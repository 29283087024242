<template>
    <div>
        <el-row type="flex" class="row-bg mainContent" justify="space-between">
            <el-col :span="14" class="mainLeft">
                <div class="title">
                    <div :class="$i18n.locale==='zh'?'slogan':'slogan sloganE'" v-html="$t('login.slogan1')"></div>
                    <span>{{ $t('login.slogan2') }}</span>
                </div>
                <div class="logo bgCommon"></div>
            </el-col>
            <el-col :span="10" class="mainRight">
                <div class="main">
                    <h1 class="logo">
                        <img src="@/assets/images/capital/bigLogo.png" alt=""/>
                    </h1>
                    <div class="tab">
                        <ul>
                            <li :class="active == 0 ? 'active' : ''">
                                {{ $t('login.authenticate') }}
                            </li>
                            <li :class="active == 1 ? 'active' : ''">
                                {{ $t('login.setPwd') }}
                            </li>
                            <li :class="active == 2 ? 'active' : ''">{{ $t('login.over') }}</li>
                        </ul>
                    </div>
                    <div class="form-mobile login-area">
                        <div class="from">
                            <div v-show="active == 0">
                                <div :class="form.loginNamemsg ? 'form-item form-item-error' : 'form-item'">
                                    <label><img src="@/assets/images/login/user.png"/></label>
                                    <input
                                        type="text"
                                        v-model="form.loginName"
                                        :placeholder="placeholder"
                                        autocomplete="off"
                                        @blur="isverify"
                                    />
                                    <p class="error" v-if="form.loginNamemsg">
                                        <img src="@/assets/images/login/wrong.png"/>
                                        {{ form.loginNamemsg }}
                                    </p>
                                </div>
                                <Verification @verification="verification" ref="Verification" />
                                <div class="yzm-item" style="margin-bottom:35px">
                                    <div :class="form.codemsg ? 'form-item form-item-error' : 'form-item'">
                                        <label><img src="@/assets/images/login/email.png" style="width: 22px; height: 23px"/></label>
                                        <input type="text" v-model="form.code" autocomplete="off" :placeholder="$t('login.code')"/>
                                        <p class="error" v-if="form.codemsg">
                                            <img src="@/assets/images/login/wrong.png"/>
                                            {{ form.codemsg }}
                                        </p>
                                    </div>
                                    <span class="yzmbtn" :disabled="disabled" @click="getVerificationCode()">{{ yzmtext }}</span>
                                </div>
                                <div class="step-btn">
                                    <span class="next form-btn" @click="Nextstep(1)">{{ $t('login.next') }}</span>
                                    <span class="goback" @click="goLogin">{{ $t('login.goLogin3') }}</span>
                                </div>
                            </div>
                            <div v-show="active == 1">
                                <div :class="form.passwordmsg ? 'form-item form-item-error' : 'form-item'" style="margin-top: 20px">
                                    <label><img src="@/assets/images/login/password.png"/></label>
                                    <input type="password" v-model="form.password" :placeholder="$t('login.verPassword')" autocomplete="off"/>
                                    <p class="error" v-if="form.passwordmsg">
                                        <img src="@/assets/images/login/wrong.png"/>{{ form.passwordmsg }}
                                    </p>
                                </div>
                                <div :class="form.confimpwdmsg ? 'form-item form-item-error' : 'form-item'">
                                    <label><img src="@/assets/images/login/qrmm.png"/></label>
                                    <input type="password" v-model="form.confimpwd" :placeholder="$t('login.confirmPwd')" autocomplete="off"/>
                                    <p class="error" v-if="form.confimpwdmsg">
                                        <img src="@/assets/images/login/wrong.png"/>{{ form.confimpwdmsg }}
                                    </p>
                                </div>
                                <div class="step-btn">
                                    <span class="next form-btn" @click="Nextstep(2)">{{ $t('login.next') }}</span>
                                    <span class="goback" @click="Nextstep(0)">{{ $t('login.previous') }}</span>
                                </div>
                            </div>
                            <div v-show="active == 2">
                                <div class="over">
                                    <div><img src="@/assets/images/login/over-icon.png"/></div>
                                    <p>{{ $t('login.setPwdSuccess') }}</p>
                                </div>
                                <div class="step-btn step-btn2">
                                    <span class="next form-btn" @click="goLogin">{{ $t('login.goLogin3') }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import qs from "qs";
var interval = null;
import Verification from "@/components/Verification/loginV";
import {recoverApi, sendMsgApi} from "@/api/service";
import md5 from 'js-md5'

export default {
    data() {
        return {
            signupType:'',
            active: 0,
            form: {
                loginName: "",
                loginNamemsg: "",
                password: "",
                passwordmsg: "",
                code: "",
                codemsg: "",
                confimpwdmsg:'',
            },
            loginType: "LOGIN_NAME",
            disabled: false,
            currentTime: 60,
            verify: false,
            slide: false,
            clientId: 952310,
            nvc: "",
            result:-1,
            yzmtext:this.$t("login.getCode"),
            placeholder:this.$t("login.userName2")
        };
    },

    components: {Verification},

    computed: {
        // yzmtext:{
        //     get(){
        //         return this.$t("login.getCode")
        //     },
		// 	set(value){} 
        // },
        // placeholder:{
        //     get(){
        //         return  this.$t("login.userName2")
        //     },
		// 	set(value){}
        // }
    },

    created() {},

    mounted() {},

    methods: {
        isverify() {
            var reg = new RegExp(
                "^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$"
            );
            if (/^1[3456789]\d{9}$/.test(this.form.loginName)) {
                this.result = 0;
                this.signupType = "PHONE";
                this.form.loginNamemsg='';
            } else if (reg.test(this.form.loginName)) {
                this.result = 1;
                this.signupType = "EMAIL";
                this.form.loginNamemsg='';
            } else {
                this.result = -1;
                this.signupType = "";
                this.form.loginNamemsg = this.$t('login.verUserName');
                return false;
            }
            window.nc.reset();
        },
        verification(data) {
            if (data) {
                this.nvc = data.nvc;
                this.verify = true;
            }
        },
        async getVerificationCode() {
            if(this.result === -2){
                return false;
            }
            if (this.result === -1) {
                this.form.loginNamemsg = this.$t('login.verUserName');
                return false;
            } else {
                this.form.loginNamemsg = "";
            }
            
            if (!this.verify) {
                this.$diamsg(this.$t('login.varSlide'));
                return false;
            }
            this.getCode();
            var that = this;
            that.disabled = true;
            var data = qs.stringify({
                signupType: that.signupType,
                loginName: that.form.loginName,
                verifyLoginName:2,
                nvc: that.nvc,
            });
            sendMsgApi(data).then((res) => {
                that.$msg(that.$t('login.sendCode'), "success");
            }).catch((error) => {
                let err=error.data;
                if(err.code === 2 || err.code ===600006){//账户未注册,请先注册
                    that.form.loginNamemsg = err.message;
                    that.result = -2;
                    that.disabled = false;
                    clearInterval(interval);
                    that.yzmtext = that.$t('login.getCode');
                    that.currentTime = 60;
                    return false
                }
                if (err.code === 403) {
                    that.$msg(err.message);
                    return false;
                }
                if (err.code === 100 || err.code === 200) {
                    that.$msg(that.$t('login.sendCode'), "success");
                    that.$refs.Verification.resetNvc();
                } else if (err.code === 800 || err.code === 900) {
                    // 无痕验证失败，直接拦截
                    that.$msg(that.$t("login.vError"));
                    that.disabled = false;
                    clearInterval(interval);
                    that.yzmtext = that.$t("login.againGetCode");
                    that.currentTime = 60;
                    that.disabled = false;
                } else if (err.code === 400) {
                    that.disabled = false;
                    clearInterval(interval);
                    that.yzmtext = that.$t("login.againGetCode");
                    that.currentTime = 60;
                    that.disabled = false;
                    that.$refs.Verification.resetNvc();
                }
            });
        },
        getCode() {
            var that = this;
            var currentTime = that.currentTime;
            interval = setInterval(function () {
                currentTime--;
                that.yzmtext = "(" + currentTime + "s)"+that.$t("login.againGetCode");
                if (currentTime <= 0) {
                    clearInterval(interval);
                    that.yzmtext = that.$t("login.againGetCode");
                    that.currentTime = 60;
                    that.disabled = false;
                }
            }, 1000);
        },
        goLogin(){
            this.$router.push({
                path:'/login',
                query: this.$route.query,
            })
        },
        Nextstep(n) {
            if (n == 1) {
                if (this.result === -1) {
                    this.form.loginNamemsg = this.$t('login.verUserName');
                    return false;
                } else {
                    this.form.loginNamemsg = "";
                }
                if(this.result === -2){
                    this.form.loginNamemsg = this.$t('login.goReg2');
                    return false;
                }
                if (!this.form.code && !this.form.code.trim()) {
                    this.form.codemsg = this.$t('login.code');
                    return false;
                }else{
                    this.form.codemsg = "";
                }
                if (!this.verify) {
                    this.$diamsg(this.$t('login.varSlide'));
                    return false;
                } else {
                    window.nc.reset();
                    this.slide = false;
                    this.verify = false;
                }
                this.active = n;
            } else if (n == 2) {
                if(!/^[A-Za-z0-9]{6,20}$/.test(this.form.password)){
                    this.form.passwordmsg = this.$t('login.verPassword')
                    return false;
                }else{
                    this.form.passwordmsg = "";
                }
                if (!this.form.confimpwd && !this.form.confimpwd.trim()) {
                    this.form.confimpwdmsg = this.$t('login.confirmPwd');
                    return false;
                }else{
                    this.form.confimpwdmsg = "";
                }
                if (this.form.confimpwd !== this.form.password) {
                    this.form.confimpwdmsg = this.$t('login.contrastPwd');
                    return false;
                }else{
                    this.form.confimpwdmsg = "";
                }
                let that = this;
                var loading = this.$layer.loading({
                    shade: true,
                });
                var params = qs.stringify({
                    loginName: that.form.loginName.replace(/(^\s*)|(\s*$)/g, ""),
                    signupType: that.signupType,
                    password: md5(that.form.password.replace(/(^\s*)|(\s*$)/g, "")),
                    code: that.form.code.replace(/(^\s*)|(\s*$)/g, ""),
                });
                recoverApi(params).then((res) => {
                    console.log(res);
                    this.$layer.close(loading);
                    this.active = 2;
                }).catch((err) => {
                    this.$layer.close(loading);
                    console.log(err);
                    that.$diamsg(err.data.message);
                });
            } else {
                this.active = n;
            }
        },
    },
};
</script>
<style lang="less" src="@/assets/css/login.less" scoped></style>
